import "./App.css";

import * as bc from "bc-js";
import { useEffect, useState } from "react";
import * as jscybermetrie from '@oel/js-cybermetrie';
import * as configuration from "@oel/js-configuration";
import * as authentification from "@oel/js-authentification";


import { Cadenas } from "../commun/Cadenas/Cadenas";
import { ResilienceUtil } from "../../utils/ResilienceUtil";
import { Transactions } from "../particulier/transactions/Transactions";
import { GabaritNavigationUtil } from "../../utils/GabaritNavigationUtil";
import { AccueilAffaires } from '../affaires/accueil-affaires/AccueilAffaires';
import { DetailCarteCredit } from "../affaires/carte-credit/DetailCarteCredit";
import { DetailCompteCredit } from "../affaires/compte-credit/DetailCompteCredit";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AccueilParticulier } from "../particulier/accueil-particulier/AccueilParticulier";
import { DetailsCompteBancaire } from "../particulier/compte-bancaire/DetailsCompteBancaire";
import { WindowService } from "../../services/window/window-service";
import { SjtiService } from "../../services/sjti/sjti-service";
import { EtatPageEnum } from "../../modeles/EtatPageEnum";

export default function App() {
    const [configurationGabarit, setConfigurationGabarit] = useState<any>(GabaritNavigationUtil.obtenirConfigurationGabarit());
    const [chargementSessionComplete, setChargementSessionComplete] = useState<boolean>(false);
    const [obtentionDonneesComplete, setObtentionDonneesComplete] = useState<boolean>(WindowService.nativeWindow().sessionStorage.getItem('obtention-donnees-complete-' + SjtiService.obtenirSjti()) === "true");
    const [chargementSessionLegacyComplete, setChargementSessionLegacyComplete] = useState<boolean>(false);
    const [gabaritComplete, setGabaritComplete] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const estAccueilAuPremierChargement = location.pathname.endsWith("/accueil") || location.pathname === "/";
    const [estOuvertureSession] = useState<boolean> (WindowService.getCookie("atk_ctx_accesd") == null);
    const [etatPage, setEtatPage] = useState(EtatPageEnum.PageBlanche);

    function estAda() {
        return configuration.ConfigurationService.getConfig("oel-contexte-applicatif") === "ADA";
    }

    useEffect(() => {
        const chargerSpa = () => {
            window.removeEventListener("@authentification/v1/chargement-session-complete", chargerSpa);
            if (!globalThis.navigation || !Object.keys(globalThis.navigation).length) {
                console.error("HDS000001: window.navigation non disponible");
                window.location.href = `${document.querySelector('meta[name="oel-acces-web-url"]')?.getAttribute('content')}/identifiantunique/autologout?logon=true&erreurs=HDS000001&raison=002`;
            }

            if (isMounted) setChargementSessionComplete(true);
            if (configuration.ConfigurationService.getConfig("oel-biocomp-active") === 'true') {
                ResilienceUtil.rendreResilientSiProbleme("bc", () => bc.injecterScript(true));
            }
        };

        const chargerSessionLegacy = () => {
            window.removeEventListener("@authentification/v1/chargement-session-legacy-complete", chargerSessionLegacy);
            GabaritNavigationUtil.declencherSessionLegacyCharge();
            if (isMounted) {
                setConfigurationGabarit(GabaritNavigationUtil.obtenirConfigurationGabarit());
                setChargementSessionLegacyComplete(true);
            }
        };

        const afficherGabarit = () => {
            window.removeEventListener("navigationReady", afficherGabarit);
            if (isMounted) setGabaritComplete(true);
        };

        const navigationEvenement = (detailEvenement) => {
            const itemId = detailEvenement?.detail?.itemId;

            if (itemId === 'recherche-transaction') {
                navigate("/transactions");
            } else if (itemId === 'accueil') {
                navigate("/accueil");
            }
        };

        const declencherObtentionDonneesComplete = () => {
            window.removeEventListener("@hub-detention/obtention-donnees-complete", declencherObtentionDonneesComplete);
            if (isMounted) {
                setObtentionDonneesComplete(true);
            }
            WindowService.nativeWindow().sessionStorage.setItem('obtention-donnees-complete-' + SjtiService.obtenirSjti(), true)
        }

        let isMounted = true;
        ResilienceUtil.rendreResilientSiProbleme("jscybermetrie", () => jscybermetrie.CybermetrieService.initialiser());
        ResilienceUtil.rendreResilientSiProbleme("jscybermetrie", () => jscybermetrie.EvenementCybermetrieService.ecouterEvenementService());

        window.addEventListener("@authentification/v1/chargement-session-complete", chargerSpa);
        window.addEventListener("@authentification/v1/chargement-session-legacy-complete", chargerSessionLegacy);
        window.addEventListener("navigationReady", afficherGabarit);
        window.addEventListener("@hub-detention/obtention-donnees-complete", declencherObtentionDonneesComplete);
        window.addEventListener("@navigation-microfrontend/v1/naviguer-vers-page", navigationEvenement);
        authentification.initialiser();

        return () => {
            isMounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // À effectuer à chaque changement de page
    useEffect(() => {
        GabaritNavigationUtil.reinitialiserFilAriane();
    }, [navigate]);

    // À effectuer lorsque le GabaritNavigation et les données de AccueilAffaires sont chargés
    // Sert à afficher le cadenas de chargement ou spinner jusqu'au chargement complet d'ADA ou d'ADP
    useEffect(() => {
        if(etatPage !== EtatPageEnum.PagePreteAffichage) {
            if (estAccueilAuPremierChargement) {
                if (obtentionDonneesComplete) {
                    if (gabaritComplete) {
                        setEtatPage(EtatPageEnum.PagePreteAffichage);   
                    }
                } else if (estOuvertureSession) {
                    setEtatPage(EtatPageEnum.Cadenas);
                } else {
                    setEtatPage(EtatPageEnum.Spinner);
                }
            } else {
                if (gabaritComplete) {
                    setEtatPage(EtatPageEnum.PagePreteAffichage);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [obtentionDonneesComplete, gabaritComplete]);

    function chargementRoutes() {
        if (estAda()) {
            if (gabaritComplete) {
                return (
                    <>
                        <Route path="/accueil" element={<AccueilAffaires />} />
                        <Route path="/comptes/carte-credit" element={<DetailCarteCredit />} />
                        <Route path="/comptes/compte-credit" element={<DetailCompteCredit />} />
                        {chargementRoutesFallback()}
                    </>
                );
            }
        } else {
            return (
                <>
                    <Route path="/accueil" element={<AccueilParticulier estSessionLegacyCharge={chargementSessionLegacyComplete}></AccueilParticulier>} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/comptes/courant/:idContexte" element={<DetailsCompteBancaire />} />
                    {chargementRoutesFallback()}
                </>
            )
        }
    }

    function chargementRoutesFallback() {
        return <>
            <Route path="/" element={<Navigate to={{ pathname: "/accueil", search: location.search }} replace />} />
            <Route path="*" element={<Navigate to={{ pathname: "/accueil", search: location.search }} replace />} />
        </>;
    }
    
    function afficherSpinner() {
        return (
            //fix temporaire le temps de passer à DSD-MFE 2.6.0
            <div className="zone">
                <div className="backdrop"></div>
                <div className="content-inner">
                    <div className="spinner">
                        <dsd-loading id="donneesObtenues"></dsd-loading>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {etatPage === EtatPageEnum.Cadenas && <Cadenas />}
            {etatPage === EtatPageEnum.Spinner && afficherSpinner()}
            {chargementSessionComplete &&
                <div className={`App ${etatPage !== EtatPageEnum.PagePreteAffichage ? "dsd-d-none" : ""}`}>
                    <gabarit-global configuration={JSON.stringify(configurationGabarit)}>
                        <div> {/* Ne pas enlever la div, ceci cause sinon un problème lors du routing vers d'autres pages de la SPA */}
                            <Routes>
                                {chargementRoutes()}
                            </Routes>
                        </div>
                    </gabarit-global>
                    <accesd-conavigation />
                </div>
            }
        </div>
    );
}
