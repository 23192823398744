import { useEffect } from "react";
import { LangueUtils } from "@oel/js-i18n";
import { en } from "./i18n/DetailCarteCredit.i18n.en";
import { fr } from "./i18n/DetailCarteCredit.i18n.fr";
import { GabaritNavigationUtil } from "../../../utils/GabaritNavigationUtil";

export function DetailCarteCredit() {
    const libelles = new LangueUtils(fr, en).obtenirLibelles();
    
    useEffect(() => {
        document.title = libelles.titreOnglet;
        GabaritNavigationUtil.afficherMenuDroite(false);
        GabaritNavigationUtil.afficherTiroirPrimeurs(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div>
            <detail-carte-credit/>
        </div>
    );
}
